import React, { Component } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import scott_img from './imgs/scott_photo.jpg';
import plot_img from './imgs/imgplot.png';
import logo from'./imgs/logo.png';
import Gallery from "react-photo-gallery";

import photo1 from './imgs/photo-1.jpg';
import photo2 from './imgs/photo-2.jpg';
import photo3 from './imgs/photo-3.jpg';
import photo4 from './imgs/photo-4.jpg';
import photo5 from './imgs/photo-5.jpg';
import photo6 from './imgs/photo-6.jpg';
import photo7 from './imgs/photo-7.jpg';
import photo8 from './imgs/photo-8.jpg';
import photo9 from './imgs/photo-9.jpg';
import photo10 from './imgs/photo-10.jpg';
import photo11 from './imgs/photo-11.jpg';
import photo12 from './imgs/photo-12.jpg';
import photo13 from './imgs/photo-13.jpg';
import photo14 from './imgs/photo-14.jpg';
import photo15 from './imgs/photo-15.jpg';
import photo16 from './imgs/photo-16.jpg';
import photo17 from './imgs/photo-17.jpg';
import photo18 from './imgs/photo-18.jpg';
import prop_5056 from './imgs/5056_east_hinsdale_place.jpg';
import prop_7137 from './imgs/7137_south_elm_ct.jpg';
import prop_7389 from './imgs/7389_south_elm_ct.jpg';
import prop_7423 from './imgs/7423_south_dexter_way.jpg';
import prop_7475 from './imgs/7475_south_elm_court.jpg';

const photos = [
    {
        src: photo1,
        width: 4,
        height: 3
    },
    {
        src: photo2,
        width: 1,
        height: 1
    },
    {
        src: photo3,
        width: 3,
        height: 4
    },
    {
        src: photo4,
        width: 3,
        height: 4
    },
    {
        src: photo5,
        width: 3,
        height: 4
    },
    {
        src: photo6,
        width: 4,
        height: 3
    },
    {
        src: photo7,
        width: 4,
        height: 3
    },
    {
        src: photo8,
        width: 4,
        height: 3
    },
    {
        src: photo9,
        width: 4,
        height: 3
    },
    {
        src: photo10,
        width: 4,
        height: 3
    },
    {
        src: photo11,
        width: 4,
        height: 3
    },
    {
        src: photo12,
        width: 4,
        height: 3
    },
    {
        src: photo13,
        width: 4,
        height: 3
    },
    {
        src: photo14,
        width: 4,
        height: 3
    },
    {
        src: photo15,
        width: 4,
        height: 3
    },
    {
        src: photo16,
        width: 4,
        height: 3
    },
    {
        src: photo17,
        width: 4,
        height: 3
    },
    {
        src: photo18,
        width: 4,
        height: 3
    }


];

class App extends Component {


  renderNotes() {
      return (
          <p>
              <img className={"img-fluid rounded float-left"} src={photo5} />
              Scott Fleming PE, CPC, is a Licensed Professional Engineer and a Certified Professional Constructor through the American Institute of Constructors.
              <br />
              <br />
              Scott has forty years of construction and engineering design experience in the USA in Colorado, California, New Mexico, Texas; in South America; and in the Country of Mexico.
              <br />
              <br />


              His engineering design experience includes site development engineering, erosion and sedimentation control designs and management, QA/QC oversight, site haul road and grading/drainage designs, site permitting, hydrologic stilling basins, cofferdams, waste water pumping, construction phase drainage routing, gravel processing facilities, gravity and pressure water delivery systems, private and public site development, State & Federal highway and intersection designs, mass grading designs and tradeoff study value engineering.
<br />
              <br />


              His construction management experience includes many of these same types of projects. His construction implementation experience includes heavy equipment mass grading, site development, and construction/development of residential, commercial, and industrial projects.

          </p>
      )
  }

  renderAbout() {
      return (
          <div className="p-5 about" id="u463-186">
              <h1 id={"about"}>About Us</h1>
              <hr />
              <p id="u463-2" className={"font-weight-bolder"}>
                  Scott Fleming PE, CPC<br />
                  Professional Qualifications/Registration(s)<br />
                  Professional Engineer #37663 in Colorado AIC Certified Professional Constructor<br />
                  Certification #1955 California State Contractors License #B518900
              </p>
              <p id="u463-8">&nbsp;</p>
              <p id="u463-10" className={'heading'}>Education</p>
              <p id="u463-11">&nbsp;</p>
              <p id="u463-15">University of California, Santa Barbara, CA, BA Environmental Science, 1973 <br />Colorado
                  State University, Ft Collins, CO, BS Civil Engineering, 2002
              </p>
              <p id="u463-16">&nbsp;</p>
              <p id="u463-18" className={'heading'}>Memberships / Affiliations</p>
              <p id="u463-19">&nbsp;</p>
              <p id="u463-21">American Society of Civil Engineers</p>
              <p id="u463-23">Society for Mining, Metallurgy &amp; Exploration</p>
              <p id="u463-25">American Institute of Constructors</p>
              <p id="u463-27">Tau Beta Pi Engineering Honor Society</p>
              <p id="u463-28">&nbsp;</p>
              <p id="u463-30" className={'heading'}>Languages</p>
              <p id="u463-31">&nbsp;</p>
              <p id="u463-33">English and Spanish</p>
              <p id="u463-34">&nbsp;</p>
              <p id="u463-36" className={'heading'}>Employment History</p>
              <p id="u463-37">&nbsp;</p>
              <p id="u463-39">AMEC Environment and Infrastructure, Denver, CO &amp; Mexico, 2010 to Present</p>
              <p id="u463-41">Fleming Engineering, Inc., Durango, CO, 2003 to 2010</p>
              <p id="u463-43">Goff Engineering, Durango CO, 2002 to 2003</p>
              <p id="u463-45">Fleming Construction, Inc., CO, CA, NM, TX, and in the country of Mexico, 1974 to 2002</p>
              <p id="u463-47">Leonard Rice Consulting Water Engineers, Denver, CO, 1973 to 1974</p>
              <p id="u463-48">&nbsp;</p>
              <p id="u463-50" className={'heading'}>Representative Projects</p>
              <p id="u463-51">&nbsp;</p>
              <p id="u463-53" className={'subheading'}>Mining, Tailings and Water Management</p>
              <p id="u463-55">Endeavour Silver Corp / AMEC Environment and Infrastructure</p>
              <p id="u463-57">Bolanitos Silver Mine, Guanacevi Silver Mine, El Cubo Silver Mine, States of
                  Durango &amp; Guanajuato, Mexico</p>
              <p id="u463-59">2011 to Present. Associate Engineer and Project Manager. These three silver mines in
                  Mexico are typical of Mexico’s rich history of silver mining. There are numerous underground workings
                  for ore production that all report to a single processing location which generates raw tailings in
                  ever increasing production rates. The tailings facilities are drilled and sampled for geotechnical
                  data and modeled and studied to generate stability quantification that allows AMEC to consult the mine
                  operators and owners through the important process of properly stored tailings, reagent contamination
                  containment, wastewater and stormwater management, solution capture and storage, and fresh water
                  recovery for system replacement. Included in these projects are wet and dry storage of tailings,
                  filter press plants, cyanide neutralization process, stormwater capture, mine reclamation and closure
                  costing and reporting, permitting support, mine expansion value engineering, and overall tailings
                  process management.</p>
              <p id="u463-61">Great Panther Silver / AMEC Environment and Infrastructure</p>
              <p id="u463-63">Cata Silver Mine, Topia Silver Mine, States of Durango &amp; Guanajuato, Mexico</p>
              <p id="u463-65">2012 to Present. Associate Engineer and Project Manager. These two silver mines in Mexico
                  are also typical silver producing mines with tailings management and water resources management
                  challenges. The Cata Silver Mine is part of the Guanajuato Mine Complex which for 250 years produced
                  two-thirds of the world’s silver. These historic and large silver mine facilities have proximity to
                  urban development and thus are critical to achieve a very high level of stability and runoff output
                  quality. AMEC E&amp;I provides annual assessments for SEMARNAT, Mexico’s environmental protection
                  entity, and ongoing stormwater and solution capture assessments to facilitate the high Canadian
                  standards for environmental care.</p>
              <p id="u463-66">&nbsp;</p>
              <p id="u463-68" className={'subheading'}>Mining, Reclamation, and Oil &amp; Gas Development</p>
              <p id="u463-70">Mining Site Design / Fleming Engineering, Inc.</p>
              <p id="u463-72">King II Coal Mine, Grupos Cementos Chihuahua, México; Durango, Colorado, USA</p>
              <p id="u463-74">2006 thru 2010. Principal Engineer. The surface support facilities for a new $18MM
                  underground coal mine and the related owner-agent construction management. This project included value
                  engineering and engineering documentation site design documentation and CDRMS permitting for a thirty
                  acre coal mining and processing facility including haul road design, erosion and sedimentation control
                  designs and management, drainage and grading, portal structure and surface coal handling/processing
                  facilities, silt pond design, water delivery pumping systems and controls, sewage treatment, raw water
                  capture and delivery, support building site design, mine site utility design, and ongoing construction
                  management.</p>
              <p id="u463-76">Mining Underground Design /Fleming Engineering, Inc.</p>
              <p id="u463-78">King II Coal Mine, Grupos Cementos Energy, Chihuahua, México; Durango, Colorado, USA</p>
              <p id="u463-80">2006 thru 2010. Principal Engineer. Mine seal design and MSHA 120 PSI seal certification.
                  Fleming Engineering, Inc. provided the design of the mine’s underground water delivery design,
                  drainage facilities, roof stabilization, portal design and its construction oversight, value
                  engineering for coal handling systems, and construction management of certain underground
                  facilities.</p>
              <p id="u463-82">Bureau of Reclamation Dam Project /Fleming Engineering, Inc.</p>
              <p id="u463-84">Ridges Basin Dam, Weemunuche Construction Authority, Durango, Colorado, USA.</p>
              <p id="u463-86">2004 thru 2007. Principal Engineer. Ridges Basin Dam is a $500MM zoned earth-fill
                  embankment dam 275 feet in height and constructed with 5.25 million cubic yards of gravel filter and
                  clay soils material. Fleming Engineering, Inc. provided the engineering design for the construction
                  water pump and pipe delivery system for the coal crushing operations water demand, the cofferdam
                  drainage stilling basin, downstream care and diversion, upstream drainage routing system canals, haul
                  road designs and tunnel stabilization design during the initial years of dam construction and erosion
                  and sedimentation control designs and management.</p>
              <p id="u463-88">Reservoir Pumping Station, Ridges Basin Dam Project /Fleming Engineering, Inc.</p>
              <p id="u463-90">Durango Pumping Plant, Gardner Zempke, Farmington, New Mexico, USA</p>
              <p id="u463-92">2006. Principal Engineer. Fleming Engineering, Inc. provided the pump plant manifold, pump
                  and motor concrete and steel pedestal design documentation for the series of eight 30HP to 200HP
                  pump &amp; motors which supply adjacent Animas River water into the 120,000 acre foot Like Nighthorse
                  reservoir via a high pressure six foot diameter pipe manifold.</p>
              <p id="u463-94">Gas Field Development, Forest Service and Southern Ute Lands /Fleming Engr., Inc.</p>
              <p id="u463-96">HD Mountain and Southern Ute Tribe Units, Petrox, Inc., Meeker, Colorado, USA.</p>
              <p id="u463-98">2006-2010. Principal Engineer. Fleming Engineering, Inc. provided the access road design,
                  well pad design, grading and drainage, erosion and sedimentation control designs and management,
                  permit documentation, construction specifications, construction management oversight, and signoff
                  certifications for this sixty plus wellpad natural gas development unit in Southwest Colorado.</p>
              <p id="u463-99">&nbsp;</p>
              <p id="u463-101" className={'heading'}>Water Systems</p>
              <p id="u463-103">High Volume Irrigation Pumping, 1500 GPM Irrigation System Project /Fleming Engineering,
                  Inc.</p>
              <p id="u463-105">Animas Valley Golf and Residential Development, Sinton Development, Durango, CO, USA.</p>
              <p id="u463-107">2004. Principal Engineer. Fleming Engineering, Inc. provided the irrigation wet well and
                  flume design, pump and motor specifications and procurement, VFD control system design coordination,
                  and the construction administration for this raw water irrigation delivery system installation and
                  start-up.</p>
              <p id="u463-108">&nbsp;</p>
              <p id="u463-110" className={'heading'}>Soils Stability</p>
              <p id="u463-112">Structure Stabilization, Animas River Trail Main Ave Bridge Stabilization /Fleming
                  Engineering, Inc.</p>
              <p id="u463-114">City of Durango and Kennebeck Construction, Inc., Durango CO, USA.</p>
              <p id="u463-116">2005. Principal Engineer. Fleming Engineering, Inc. provided the stability evaluation for
                  the 100 year old Main Ave. Bridge crossing of the Animas River in Downtown Durango during the
                  construction of the Animas River Trail tunnel. An engineering evaluation was performed by Fleming
                  Engineering to insure stability of the existing one hundred plus year old arch support un-reinforced
                  masonry bridge during tunnelling and crane placement of adjacent precast concrete arch support
                  sections.</p>
              <p id="u463-117">&nbsp;</p>
              <p id="u463-119" className={'heading'}>Stormwater Quality</p>
              <p id="u463-121">Stormwater Quality Design, Crossroads Center Commercial Building /Fleming Engineering,
                  Inc.</p>
              <p id="u463-123">Crossroads Durango and Jim Hoffmann Architecture, Durango, CO, USA.</p>
              <p id="u463-125">2006-2007. Principal Engineer. Fleming Engineering, Inc. provided the demolition plan and
                  re-construction design for 40,000 sq ft of 100 plus year old railroad siding and historic downtown
                  Durango improvements area to become landscaped parking area for a downtown commercial development.
                  Stormwater quality standards per the Urban Drainage &amp; Flood Control District of Denver were
                  incorporated to clean site runoff for direct diversion into the adjacent Animas River with 80% plus
                  TSS removal.</p>
              <p id="u463-126">&nbsp;</p>
              <p id="u463-128" className={'heading'}><span id="u463-127">Workout of Distressed Projects</span></p>
              <p id="u463-130">Project Lender Workout, $20MM Completed Project /Fleming Construction, Inc.</p>
              <p id="u463-132">Settlement, LLC, Breckenridge, and Durango CO, USA.</p>
              <p id="u463-134">1997 thru 1999. Construction Manager and General Contractor. Fleming Construction, Inc.
                  provided the pre-workout schedule and budget diligence for IndyMac Lending to release workout funding
                  to recover a series of four development projects in Durango and Breckenridge Colorado which were all
                  in distressed and stalled condition due to GC performance issues. In 1999 these four projects were
                  delivered by Fleming Construction, Inc. to the IndyMac and its equity partner on-schedule and within
                  0.8% of the original workout budget.</p>
              <p id="u463-135">&nbsp;</p>
              <p id="u463-137" className={'heading'}>Floodplain Studies</p>
              <p id="u463-139">100 &amp; 500 Year Floodplain Study, FEMA /Leonard Rice Consulting Water Engineers.</p>
              <p id="u463-141">Town of Breckenridge &amp; Federal Emergency Management Agency, Breckenridge, CO,
                  USA.</p>
              <p id="u463-143">1974. Technician. Leonard Rice Engineering provided the floodplain identification for the
                  100 and 500 year return period for the Town of Breckenridge using early Fortran and DOS based computer
                  modeling. Scott Fleming provided computer generated site feature topography modeling for this 1970’s
                  era digital floodplain mapping effort.</p>
              <p id="u463-145">Hec Ras Study for FEMA /Fleming Engineering, Inc.</p>
              <p id="u463-147">Private Landowner, The County of La Plata Colorado, &amp; FEMA, Hesperus, CO, USA.</p>
              <p id="u463-149">2010. Principal Engineer. Fleming Engineering provided the Hec Ras modeling to update the
                  La Plata River 100 year floodplain delineation for the valley below La Plata Canyon near Durango. A
                  new bridge introduced modifications in the river behavior under flood conditions and this Hec Ras
                  model provided the required channel boundary and flood elevation data for the FEMA database for this
                  drainage.</p>
              <p id="u463-150">&nbsp;</p>
              <p id="u463-152" className={'heading'}>Construction Management</p>
              <p id="u463-154">Various Construction Projects /Fleming Engineering and Fleming Construction, Inc.</p>
              <p id="u463-156">Steamboat Springs, Denver, &amp; Durango CO, SF Bay Area CA, Dallas TX, and the States of
                  Jalisco, &amp; Nuevo Leon, &amp; El Distrito Federal, México.</p>
              <p id="u463-158">978-2010. Prime Constructor, CM, and/or Principal Engineer. Fleming Construction and/or
                  Fleming Engineering have provided construction management services for numerous mining, industrial,
                  commercial, and residential projects over the past thirty-plus years. A project list and further
                  details are available upon request.</p>
              <p id="u463-159">&nbsp;</p>
              <p id="u463-161" className={'heading'}>Leach Pad Design</p>
              <p id="u463-163">AMEC Environmental &amp; Infrastructure, Denver, Colorado</p>
              <p id="u463-165">Cobre del Mayo, Álamos, State of Sonora, México</p>
              <p id="u463-167">2010-2011 – Lead design engineer for 380,000 sq. ft. of leach pad for the Cobre del Mayo
                  copper mine in the Sierra Madre Occidental mountains of Northwest Mexico in the State of Sonora near
                  the city of Ciudad Obregon. This design included retrofitting new leach pad over existing solution
                  piping using trench structural designs capable of over 20,000 PSF of load on existing and flowing HDPE
                  pipe to allow for uninterrupted pregnant solution flow for the mine’s copper production demands.</p>
              <p id="u463-168">&nbsp;</p>
              <p id="u463-170" className={'heading'}>Leach Pad Construction</p>
              <p id="u463-172">AMEC Environmental &amp; Infrastructure, Denver, Colorado</p>
              <p id="u463-174">Cobre del Mayo, Álamos, State of Sonora, México</p>
              <p id="u463-176">2010-2011 – Lead site QA-QC engineer for 430,000 sq. ft. of leach pad for the Cobre del
                  Mayo copper mine. This work involved setting up QA facilities and a QC geotechnical testing lab
                  on-site in preparation for the quality control efforts of AMEC Earth and Environmental to insure that
                  the leach pad installations met design specifications and quality standards.</p>
              <p id="u463-178" className={'heading mt-4'}>Phosphate Mine Design</p>
              <p id="u463-180">AMEC Environmental &amp; Infrastructure, Denver, Colorado</p>
              <p id="u463-182">Monsanto Corporation, Soda Springs, Idaho, USA</p>
              <p id="u463-184">2011. Lead design engineer for drawings, specifications, budgets, and schedules for a
                  1485 acre high grade phosphate mine in Southeastern Idaho. Design work included pressurized piping of
                  site stormwater to various impoundments for containment and control of runoff produced during open pit
                  mining operations. Large pumps and a HDPE pipe network provides the various systems necessary to
                  provide control of stormwater movement through the site as drainage routing conditions change for the
                  life of this phosphate mine. As well the work involved specifications and bid documentation to
                  facilitate a coordinated effort to provide the client with value engineered and competitively bid
                  options for new mine construction.</p>
          </div>
      );
  }

  renderPhoto() {
      return (
          <div className={'p-5'}>
              <h1 id={'photos'}>Photos</h1>
              <hr />
              <Gallery photos={photos} />
          </div>
      )
  }

    renderProperties() {
      return (
          <div className={'p-5'}>
              <h1 id={'properties'}>Scott Fleming Properties</h1>
              <hr />
              <div className={'row'}>
                  <div className={'col'}>
                      <div className={'card'}>
                          <img className={'card-img-top'} src={prop_5056} />
                          <div className="card-body">
                              <h5 className="card-title">5056 East Hinsdale Place</h5>
                          </div>
                      </div>
                  </div>
                  <div className={'col'}>
                      <div className={'card'}>
                          <img className={'card-img-top'} src={prop_7137} />
                          <div className="card-body">
                              <h5 className="card-title">7137 South Elm Ct, Centennial</h5>
                          </div>
                      </div>
                  </div>
              </div>
              <div className={'row mt-2'}>
                  <div className={'col'}>
                      <div className={'card'}>
                          <img className={'card-img-top'} src={prop_7389} />
                          <div className="card-body">
                              <h5 className="card-title">7389 South Elm Ct, Centennial</h5>
                          </div>
                      </div>
                  </div>
                  <div className={'col'}>
                      <div className={'card'}>
                          <img className={'card-img-top'} src={prop_7423} />
                          <div className="card-body">
                              <h5 className="card-title">7423 South Dexter Way</h5>
                          </div>
                      </div>
                  </div>
              </div>
              <div className={'row mt-2'}>
                  <div className={'col'}>
                      <div className={'card'}>
                          <img className={'card-img-top'} src={prop_7475} />
                          <div className="card-body">
                              <h5 className="card-title">7475 South Elm Court</h5>
                          </div>
                      </div>
                  </div>
                  <div className={'col'}>

                  </div>
              </div>
          </div>
      );
    }

  render() {
    return (
        <div className={"bg-dark"} style={{height: "100vh"}}>
          <div className="container bg-light pt-3">
                <div className='row mt-3'>
                    <div className={'col-md-3 d-none d-md-block'}>

                        <div className={'card mx-auto shadow'} style={{maxWidth: "230px"}}>
                            <img src={logo} className={"card-img-top p-3"} />
                            <img className={"card-img-top p-3"} src={scott_img} />

                            <div className={'card-body'}>
                                <div className={'text-center'} style={{fontSize: "12px"}}>
                                    <div>Scott Fleming P.E. C.P.C.</div>
                                    <div>Centennial, CO 80122</div>
                                    <div>(303) 523-8476 Cell</div>
                                    <div>(303) 704-5302 Cell</div>
                                    <div>(303) 603-0776 Direct</div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className={'text-center mt-2'}><small>Principal & Mexico Region Manager</small></div>
                        <hr />
                        <div className={'text-center mt-2'}><small>AMEC Foster Wheeler Environment & Infrastructure</small></div>
                        <hr />
                        <div className={'text-center mt-2'}>
                            2000 South Colorado Blvd.<br />
                            Bldg #2 Suite 1000<br />
                            Denver, CO 80222<br />
                        </div>
                    </div>
                    <div className={'col text-center mt-2'}>

                        <ul className="nav">
                            <li className="nav-item">
                                <a className="nav-link mygreen" href="#about">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link mygreen" href="#photos">Photos</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link mygreen" href="#properties">Properties</a>
                            </li>
                        </ul>

                        <div className={'text-left justify-content-center mt-3'}>
                            <div className={'row'}>
                                <div className={'col-md fsize'}>
                                    {this.renderNotes()}
                                </div>
                                <div className={'col-md'}>
                                    <img className={"img-fluid img-thumbnail mx-auto"} src={plot_img} />
                                    <div className={'row mt-3'}>
                                        <div className={'col-md'}>
                                            <img className={"img-fluid rounded float-left"} src={photo1} />
                                        </div>
                                        <div className={'col-md'}>
                                            <img className={"img-fluid rounded float-left"} src={photo2} />
                                        </div>
                                    </div>

                                    <div className={'row mt-2'}>
                                        <div className={'col-md'}>
                                            <img className={"img-fluid rounded float-left"} src={photo3} />
                                        </div>
                                        <div className={'col-md'}>
                                            <img className={"img-fluid rounded float-left"} src={photo4} />
                                        </div>
                                    </div>
                                    <div className={'row mt-2'}>
                                        <div className={'col-md'}>
                                            <img className={"img-fluid rounded float-left"} src={photo5} />
                                        </div>
                                        <div className={'col-md'}>
                                            <img className={"img-fluid rounded float-left"} src={photo6} />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                </div>
                </div>
              <hr />
              <div>
                  {this.renderAbout()}
              </div>
              <hr />
              <div>
                  {this.renderPhoto()}
              </div>
              <hr />
              <div>
                  {this.renderProperties()}
              </div>
              <div style={{height: "100px"}}>
              </div>
          </div>
        </div>
    );
  }
}

export default App;
